import { useEffect, useState } from 'react';
import { User } from 'services/userService';
import { useLogger, useSnackbars } from 'hooks';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { getConnectedStatus } from 'services/userService';
import { LoggedinDevice, getLoggedinDevices } from 'services/deviceService';
import { Apple } from './components/Apple';
import { Google } from './components/Google';
import { BankId } from './components/BankId';
import { Title } from './components/Title/Title';
import { ConnectedStatusResponse, useIsConnectedFederator } from './hooks/useIsConnectedFederator';
import { isNonEmptyObject } from './utils/isNonEmptyObject.utils';

type FederatedLoginProps = {
  user?: User;
};

export const FederatedLogin = ({ user }: FederatedLoginProps) => {
  const [connectionChecked, setConnectionChecked] = useState(false);
  const { createNegativeSnackbar } = useSnackbars();
  const logger = useLogger('navigation/account/get-federated-connections');
  const [thisDevice, setThisDevice] = useState<LoggedinDevice | undefined>();
  const { data } = useFetchFunction(getLoggedinDevices);
  const [theDataConnectedStatus, setTheDataConnectedStatus] = useState<
    ConnectedStatusResponse | {}
  >();
  const { data: dataConnectedStatus } = useFetchFunction(getConnectedStatus);

  const { errorMessage, error, connectedFederator, setConnectedFederator } =
    useIsConnectedFederator({
      dataConnectedStatus: theDataConnectedStatus,
    });

  useEffect(() => {
    if (data) {
      setThisDevice(data.thisDevice);
    }
  }, [data]);

  useEffect(() => {
    if (user && !connectionChecked) {
      setConnectionChecked(true);
    }
  }, [connectionChecked, user]);

  useEffect(() => {
    if (isNonEmptyObject(dataConnectedStatus)) {
      setTheDataConnectedStatus(dataConnectedStatus);
    }
  }, [dataConnectedStatus]);

  if (error) {
    logger.error('Failed to fetch connections', error);
  }

  if (errorMessage) {
    createNegativeSnackbar(errorMessage);
  }

  return (
    <>
      <li>
        <Title />
      </li>
      <li>
        <BankId />
      </li>
      <li>
        <Apple
          isAppleConnected={connectedFederator.includes('apple')}
          setIsAppleConnected={setConnectedFederator('apple')}
          thisDevice={thisDevice}
        />
      </li>
      <li>
        <Google
          isGoogleConnected={connectedFederator.includes('google')}
          setIsGoogleConnected={setConnectedFederator('google')}
          thisDevice={thisDevice}
        />
      </li>
    </>
  );
};
