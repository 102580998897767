import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { mq } from '@telia-company/tv.oneapp-web-ui';

const headerHeight = 56;
const footerHeight = 171;

export const StyledNavigationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    max-width: 1064px;
    margin: 0 auto;

    flex-direction: column;
    background-color: transparent;

    min-height: calc(100vh - ${headerHeight}px - ${footerHeight}px);

    ${mq.S`
      flex-direction: row;
      background-color: ${theme.designTokens.color.background.secondary};
    `}

    ${mq.L`
      margin-top: 89px;
      border-radius: 12px;
      min-height: 796px;
    `}
  `}
`;

export const StyledMain = styled.main`
  width: 100%;
  overflow: auto;
  padding: 16px;

  ${mq.S`
    padding: 32px 40px 32px 16px;
  `} ${mq.L`
    padding: 32px 48px;
  `};
`;
