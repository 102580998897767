import { useState } from 'react';
import { UpdatePinCodeDone, UpdatePinCodeForm } from './components';

export const UpdatePinCode = () => {
  const [pinCodeUpdated, setPinCodeUpdated] = useState(false);

  const onPinCodeUpdated = () => {
    setPinCodeUpdated(true);
  };

  return (
    <main>
      {pinCodeUpdated ? <UpdatePinCodeDone /> : <UpdatePinCodeForm onSuccess={onPinCodeUpdated} />}
    </main>
  );
};
